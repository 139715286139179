<template>
  <b-card>
    <b-overlay
      :show="!rows"
      rounded="sm"
    >
      <div class="invoice-table">
        <!-- search input -->
        <div class="custom-search d-flex justify-content-end">
          <b-form-group>
            <div class="d-flex align-items-center">
              <label class="mr-1">Search</label>
              <b-form-input
                v-model="searchTerm"
                placeholder="Search"
                type="text"
                class="d-inline-block"
              />
            </div>
          </b-form-group>
        </div>

        <!-- table -->
        <vue-good-table
          v-if="rows"
          :columns="columns"
          :rows="rows"
          :sort-options="{
            enabled: true,
          }"
          :search-options="{
            enabled: true,
            externalQuery: searchTerm }"
          :pagination-options="{
            enabled: true,
            perPage:pageLength
          }"
        >
          <template
            slot="table-row"
            slot-scope="props"
          >

            <!-- Column: Invoice No -->
            <span v-if="props.column.field === 'customInvoiceNo'">
              <router-link
                :to="`custom-invoices/${props.row.invoice_id}`"
              >
                {{ props.row.customInvoiceNo }}
              </router-link>
            </span>
            <!-- Column: Invoice No -->
            <span v-else-if="props.column.field === 'customUsername'">
              <router-link
                :to="`user/details/${props.row.uid}`"
              >
                {{ props.row.customUsername }}
              </router-link>
            </span>

            <!-- Column: status -->
            <span v-else-if="props.column.field === 'invoice_status'">
              <b-badge
                v-if="props.row.invoice_status === 'pending'"
                variant="warning"
                class="text-white"
              >
                {{ props.row.invoice_status }}
              </b-badge>
              <b-badge
                v-else-if="props.row.invoice_status === 'paid'"
                variant="success"
                class="text-white"
              >
                {{ props.row.invoice_status }}
              </b-badge>
              <b-badge
                v-else
                variant="danger"
                class="text-white"
              >
                {{ props.row.invoice_status }}
              </b-badge>
            </span>

            <!-- Column: Common -->
            <span v-else>
              {{ props.formattedRow[props.column.field] }}
            </span>
          </template>

          <!-- pagination -->
          <template
            slot="pagination-bottom"
            slot-scope="props"
          >
            <div class="d-flex justify-content-between flex-wrap mt-1">
              <div class="d-flex align-items-center mb-0 mt-1">
                <span class="text-nowrap ">
                  Showing 1 to
                </span>
                <b-form-select
                  v-model="pageLength"
                  :options="['10','25','50']"
                  class="mx-1"
                  @input="(value)=>props.perPageChanged({currentPerPage:value})"
                />
                <span class="text-nowrap"> of {{ props.total }} entries </span>
              </div>
              <div>
                <b-pagination
                  :value="1"
                  :total-rows="props.total"
                  :per-page="pageLength"
                  first-number
                  last-number
                  align="right"
                  prev-class="prev-item"
                  next-class="next-item"
                  class="mt-1 mb-0"
                  @input="(value)=>props.pageChanged({currentPage:value})"
                >
                  <template #prev-text>
                    <feather-icon
                      icon="ChevronLeftIcon"
                      size="18"
                    />
                  </template>
                  <template #next-text>
                    <feather-icon
                      icon="ChevronRightIcon"
                      size="18"
                    />
                  </template>
                </b-pagination>
              </div>
            </div>
          </template>
        </vue-good-table>
      </div>
    </b-overlay>
  </b-card>
</template>

<script>
import {
  BPagination, BFormGroup, BFormInput, BFormSelect, BCard, BOverlay, BBadge,
} from 'bootstrap-vue'
import { VueGoodTable } from 'vue-good-table'
import moment from 'moment'
import axios from '@/libs/axios'

export default {
  components: {
    VueGoodTable,
    BPagination,
    BFormGroup,
    BFormInput,
    BFormSelect,
    BOverlay,
    BCard,
    BBadge,
  },
  data() {
    return {
      invoiceLinkText: '',
      pageLength: 10,
      dir: false,
      columns: [
        {
          label: 'Invoice #',
          field: 'customInvoiceNo',
        },
        {
          label: 'Package',
          field: 'customPackage',
        },
        {
          label: 'Name',
          field: 'customUsername',
        },
        {
          label: 'Transaction Date',
          field: 'customIssueDate',
        },
        {
          label: 'Invoice Value',
          field: 'invoiceValue',
        },
        {
          label: 'Invoice Status',
          field: 'invoice_status',
        },
        {
          label: 'Credit',
          field: 'credit',
        },
        {
          label: 'Credit Status',
          field: 'customCreditStatus',
        },

      ],
      rows: [],
      searchTerm: '',
    }
  },
  mounted() {
    this.fetchAntiFraudUsers()
  },
  methods: {
    formatDate(Date) {
      return moment(Date).format('DD MMM YYYY')
    },
    fetchAntiFraudUsers() {
      const uri = '/api/antifraud/get_users'
      axios.get(uri).then(res => {
        res.data.forEach(item => {
          let creditStatus = ''

          if (item.is_success_add_credit) creditStatus = 'Successfully added Anti-fraud Credits'
          else if (item.admin_domain) creditStatus = `Bad Domain Name (${item.admin_domain})`
          else creditStatus = 'Payment required'

          const info = {
            customInvoiceNo: `TMC${String(item.invoice_number_v2).padStart(4, '0')}`,
            customPackage: item.package_name,
            customUsername: `${item.forename} ${item.surname} (${item.email})`,
            customIssueDate: this.formatDate(item.created_at),
            invoiceValue: `${item.currency} ${item.total}`,
            invoice_status: item.invoice_status,
            invoice_id: item.invoice_id,
            uid: item.uid,
            credit: item.credit,
            customCreditStatus: creditStatus,
          }
          this.rows.push(info)
        })
      })
    },
  },
}
</script>

<style lang="scss" >
@import '@core/scss/vue/libs/vue-good-table.scss';

.invoice-table {
  thead tr{
    background: #f3f2f7;
  }

  th, td {
    font-size: 14px;
    text-align: center !important;
    vertical-align: middle !important;
  }
}
</style>

<style lang="scss" scoped>
.dark-layout a {
  color: #fb50a1 !important;
}
</style>
